// this should only be used in top level /pages/**/container!
import { useEffect, useState } from 'react'

import load from '@eaze/load-script-global'
import pole from '@eaze/pole'
import Visibility from 'document-visibility'
import window from 'global/window'
import Router from 'next/router'
import { useDispatch } from 'react-redux'

import siftEvent from 'helpers/sift'
import { setVersionMismatch } from 'redux/app/actions'
import { rehydrateCookies } from 'redux/cookies/actions'

const visibility = Visibility()
const POLL_FREQUENCY = 90000 * 1000

export default function usePageLogic() {
  const dispatch = useDispatch()
  const [version, setVersion] = useState(null)
  const [versionPoll, setVersionPoll] = useState(null)
  const [visible, setVisible] = useState(visibility.visible)
  const unlistenVisibility = visibility.onChange((visible) => setVisible(visible))

  useEffect(() => {
      const reduxStore = window.__NEXT_REDUX_WRAPPER_STORE__
      const state = reduxStore.getState()
      const dispatch = reduxStore.dispatch
      rehydrateCookies(dispatch)

      if (!window._sift) {
        load({ url: 'https://cdn.siftscience.com/s.js', global: '_sift' }, (err) => err && console.warn(err))
      }

      Router.events.on('routeChangeStart', () => {
        siftEvent(state)
      })

      startPolling()
      deregisterServiceWorker()

      return () => {
        if (unlistenVisibility) unlistenVisibility()
        cancelPolling()
      }
  }, [])

  useEffect(() => {
    visible ? startPolling() : cancelPolling()
  }, [visible])

  async function deregisterServiceWorker(): Promise<void> {
    if (window.navigator && navigator.serviceWorker) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const registration of registrations) {
        registration.unregister()
      }
    }
  }
  async function fetchVersion (callback) {
    // we don't care about this during development, so lets return early
    if (!window.location || !window.location.origin) return callback(null)

    // let's hit out root/version and see what version we're on
    try {
      const res = await fetch(`${window.location.origin}/status`)
      const body = res.headers.get('content-type').includes('json') ? await res.json() : await res.text()
      const serverVersion = body.version

      if (!res.ok) {
        console.info(`FetchVersion request status ${res.status} not OK`, body)
        throw new Error(`FetchVersion request status ${res.status} not OK`)
      }
      //  we don't have a version yet? let's save the commit hash in state.
      if (version === null && serverVersion) {
        setVersion(serverVersion)
      } else if (version !== null && version !== serverVersion) {
        // we have state.version and serverVersion isn't equal to it.. let's refresh the app
        // to ensure the users are on the latest version
        dispatch(setVersionMismatch())
      }

      return callback(null, res)
    } catch (err) {
      return callback(err, null)
    }
  }

  function startPolling () {
    const versionPoll = pole({ interval: POLL_FREQUENCY }, (callback) => fetchVersion(callback))

    setVersionPoll(versionPoll)
    console.info(`Polling for app version at frequency: ${POLL_FREQUENCY}`)

  }

  const cancelPolling = () => {
    versionPoll && versionPoll.cancel()
    setVersionPoll(null)
  }
return null

}